.App {
  text-align: center;
}
/*test*/

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.center {
  margin: auto;
  width: 50%;
  padding: 10px;
  text-align: center;
  vertical-align: middle;
  line-height: 90px;
  /* The same as your div height */
}

.detailsListFix {
  max-height: 40vh;
  overflow-x: auto;
  overflow-y: auto;
  padding-top: 20px;
  padding-right: 4px;
  padding-bottom: 20px;
  padding-left: 4px;
  position: relative;
}

.pointerMouse:hover {
  cursor: pointer;
}

.backgroundCyan:hover {
  background-color: cyan;
}

.backgroundYellow {
  background-color: yellow;
}

body { 
  padding-top: 65px; 
}